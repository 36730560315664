import * as yup from "yup";

export interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
export interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export const requestFormSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup.string().email('A valid email address is required').required('A valid email address is required'),
  addressPT: yup.mixed<PlaceType>().required('Mailing Address is required'),
  agreedToTerms: yup.string().required()
});

export interface RequestFormValues extends yup.InferType<typeof requestFormSchema> { }

export const registerFormSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup.string().email('A valid email address is required').required('A valid email address is required'),
  phone: yup.string().matches(/\d{9,9}/, {
      excludeEmptyString: true,
      message: 'Must be 9 digits',
    }).optional(),
  addressPT: yup.mixed<PlaceType>().required('Mailing Address is required'),
  item_description: yup.string().optional(),
  found_instructions: yup.string().optional(),
  preferences: yup.mixed().oneOf(["any", "email", "phone"]).required(),
  agreedToTerms: yup.string().required()
});

export interface RegisterFormValues extends yup.InferType<typeof registerFormSchema> { }

export type FoundProps = {
  isValidTag: boolean
  isRegistered: boolean
  registration: any | null | undefined
  url_id: number
  slug: string
}

export type TagRequestRegisterProps = {
  id: string;
  slug: string;
}

export type TagRequestFoundProps = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  address: string;
  item_description: string | null;
  found_instructions: string | null;
  preferences: string;
};

export type SubmissionState = "incomplete" | "submitting" | "success"  | "error"

export const loadingHelperTexts: string[] = [
  "It's around here somewhere. Bear with us...",
  "Checking a few more filing cabinets.."
]

export const submittingHelperTexts: string[] = [
  "Looks like there's a long line of submissions, give us a sec...",
  "Alright, we're up next, almost done..."
]
