import React from "react"

interface RedirectProps {
  url: string
}

const RedirectLanding: React.FC<RedirectProps> = ({url}: RedirectProps) => {
  React.useEffect(() => {
    window.location.replace(url)
  }, [url])

  return (<></>)
}

export default RedirectLanding