import { Box, Typography } from "@mui/material";

const SubmissionSuccess: React.FC<any> = () => {
  return (
    <Box
      p={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" component="div">
        Thank you for requesting your QR Tag!
      </Typography>
    </Box>
      
  );
}

export default SubmissionSuccess