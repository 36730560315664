import React, { Suspense, useState } from "react"
import { Grid } from "@mui/material";
import { FoundProps, RegisterFormValues, SubmissionState, loadingHelperTexts } from "../types";
import LostItem from "../components/LostItem";
import TagInfoForm from "../components/TagInfoForm";
import InvalidPath from "../components/InvalidPath";
import Loading from "../components/Loading";
import { Await, defer, useLoaderData, useNavigate } from "react-router-dom";
import { API_URL } from "../lib/axios";
import SubmissionSuccess from "../components/SubmissionSuccess";
import SubmissionError from "../components/SubmissionError";

const sendWithRetry = async (fetchPromiseFn: () => Promise<Response>, numRetries: number = 3): Promise<any> => {
  var attemptNum = 0
  while (attemptNum <= numRetries) {
    try {
      let response =  await fetchPromiseFn()
      if (response.status === 200) {
        return response
      } else {
        console.log('Request did not return 200 response')
      }
    } catch {
      console.log('Caught error in request')
    }
    attemptNum++
  }
  console.log('max retries exceeded. returning empty')
  return {}
  
}

const sendGetWithRetry = async (url: string) => {
  return await sendWithRetry(() => {return fetch(url)})
}

const sendPostWithRetry = async (url: string, body: any) => {
  return await sendWithRetry(() => { 
    return fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
  })
}

async function getData(params: any) {
  try{
    //for testing view
    //await new Promise(r => setTimeout(r, 2000));
    // console.log(params)
    const reqUrl = `${API_URL}/found-tag?url_id=${parseInt(params.url_id)}&slug=${params.slug}`
    const response = await sendGetWithRetry(reqUrl)
    const responseBody = await response.json()
    let isValid = responseBody.isValidTag
    if (response.status !== 200) {
      console.log('Error returned. Proceeding like invalid tag.')
      isValid = false
    }

    // console.log(responseBody)
    
    return { 
      isValidTag: isValid, 
      isRegistered: responseBody.isRegistered, 
      registration: responseBody.registration,
      url_id: parseInt(params.url_id),
      slug: params.slug
    };
  } catch{
    return { 
      isValidTag: false, 
      isRegistered: false, 
      registration: undefined,
      url_id: parseInt(params.url_id),
      slug: params.slug
    };
  }
  
}

export async function loader({params}: any) {
  const dataPromise = getData(params)
  return defer({
    props: dataPromise
  })
}

const FoundItemPageInner: React.FC<FoundProps> = (props: FoundProps) => {

  const navigate = useNavigate()
  const [submissionState, setSubmissionState] = useState<SubmissionState>("incomplete")

  const onSubmitRegister = async (formData: RegisterFormValues) => {
    try {
      if (props.isRegistered !== false) {
        console.log('Shouldnt be submitting item returned registered')
        return
      }
      setSubmissionState("submitting")
      console.log(`Registering ${props.url_id}/${props.slug}`)
      
      const body: any = { ...formData };
      body['address'] = formData.addressPT.description
      body['url_id'] = props.url_id
      body['slug'] = props.slug
      console.log(body)
      const response = await sendPostWithRetry(`${API_URL}/register-tag`, body)
      console.log(response)
      if (response.status === 200) {
        // Reload page with new registraion info
				navigate(`/found/${props.url_id}/${props.slug}`);
			} else {
				setSubmissionState("error")
			}
      
    } catch (error) {
      //TODO Handle errors like non-unique email
      console.error(error);
      setSubmissionState("error")
    }
  }

  

  const formItem = () => {
    switch (submissionState) {
			case "incomplete":
				return (<TagInfoForm onSubmitForm={onSubmitRegister} title="QR Tag Register" />)
			case "submitting":
				return (<Loading message={"Submitting form..."} helperTexts={undefined} helperTimeMs={undefined} />)
			case "success":
				return (<SubmissionSuccess />)
			case "error":
				return (<SubmissionError />)
		}
  }

  const itemObject = () => {
    return props.isRegistered !== false ? (<LostItem item={props.registration} />) : formItem()
  }

  const foundComponent = () => {
    return props.isValidTag ? itemObject() : <InvalidPath />
  }

  return foundComponent()
}

const FoundItemPage: React.FC = () => {
  const {props}: any = useLoaderData()

  return (
    <Grid container p={{xs:3, sm:8}} justifyContent="center">
        <Grid item></Grid>
        <Grid item xs={12} sm={9} lg={6}
          bgcolor="#ffffff"
          borderRadius={4}
          p={2} 
        >
          <Suspense fallback={<Loading message={"Looking up tag"} helperTexts={loadingHelperTexts} helperTimeMs={8000} />}>
            <Await resolve={props}>
              {(props: FoundProps) => {
                  return (
                    <FoundItemPageInner 
                      isValidTag={props.isValidTag} 
                      isRegistered={props.isRegistered} 
                      registration={props.registration} 
                      url_id={props.url_id} 
                      slug={props.slug} />
                  )
                }
                
              }
              
            </Await>
          </Suspense>
        </Grid>
        <Grid item></Grid>
      </Grid>
  )
}

export default FoundItemPage
