import { Outlet } from "react-router-dom"
import Layout from "../components/Layout"


const Root: React.FC = () => {
  return (
    <Layout>
      <Outlet/>
    </Layout>
  )
}

export default Root