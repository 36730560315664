import React from "react"
import { Grid } from "@mui/material";
import ErrorElement from "../components/Error";
import { useRouteError } from "react-router-dom";
import Layout from "../components/Layout";

const ErrorPage: React.FC = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <Layout>
      <Grid container p={{xs:4, sm:8}} justifyContent="center">
        <Grid item></Grid>
        <Grid item xs={12} sm={9} lg={6}
          bgcolor="#ffffff"
          borderRadius={4}
          p={2} 
        >
          <ErrorElement />
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Layout>
  )
}

export default ErrorPage
