import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
  
type LoadingProps = {
  message: string | undefined,
  helperTexts: string[] | undefined,
  helperTimeMs: number | undefined
}

type HelperTextProps = {
  text: string
}

const HelperText: React.FC<HelperTextProps> = (props) => {
  return (
    <Typography sx={{ mb: 1.5 }} color="text.secondary">
      {props.text}
    </Typography>
  )
}

const Loading: React.FC<LoadingProps> = (props) => {
  const [texts, setTexts] = useState<string[]>([]);

  const showHelperTexts: boolean = (props.helperTexts !== undefined && props.helperTimeMs !== undefined)

  useEffect(() => {
    if (!showHelperTexts) {
      // Nothing to do.
      return () => {}
    }

    const timeoutIds: NodeJS.Timeout[] = [];

    props.helperTexts?.forEach((text, i) => {
      const timeoutId = setTimeout(() => {
        setTexts((prev) => [...prev, text]);
      }, props.helperTimeMs! * (i+1));

      timeoutIds.push(timeoutId);
    });

    return () => {
      timeoutIds.forEach((id) => clearTimeout(id));
    };
  }, [props, showHelperTexts]);

  return (
    <Box
      p={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" component="div">
        {props.message}
      </Typography>
      <CircularProgress sx={{marginY: 2}}/>
      {showHelperTexts && texts.map((text) => (
        <HelperText key={text} text={text} />
      ))}
    </Box>
    
  );
}

export default Loading
