import { Box, Typography } from "@mui/material";



const InvalidPath: React.FC<any> = () => {
  return (
    <Box
      p={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Typography variant="h5" component="div">
        Looks like you're lost
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        Unknown QR Tag ID. Contact <a href="mailto:support@beaconfound.com">support</a> if you believe this is an error.
      </Typography>

    </Box>
      
  );
}

export default InvalidPath