import React from "react"


const Reload: React.FC = () => {
  React.useEffect(() => {
    window.location.replace('https://home.beaconfound.com')
  }, [])

  return (<></>)
}

export default Reload