import axios, { AxiosError, AxiosRequestHeaders } from 'axios';

const BASE_URL = 'https://x3aqjkyxke.execute-api.us-east-1.amazonaws.com' // prod
// const BASE_URL = "https://y4pyg5w52a.execute-api.us-east-1.amazonaws.com" //dev
export const API_URL = `${BASE_URL}/api/v1`

export const httpClient = axios.create({ baseURL: API_URL });

// TODO: Can include other axios configuration here as we need it
// TODO: Will likely need to add error handling here

export const extractErrorsFromResponse = (error: AxiosError<any>) => {
  const errorMessages: {
    [key: string]: string;
  }[] = error?.response?.data?.errors ? Object.values(error?.response?.data.errors) : [];

  return errorMessages;
};

export const setAccessToken = (accessToken: string) => {
  httpClient.interceptors.request.use(config => {
    (config.headers as AxiosRequestHeaders).authorization = `Bearer ${accessToken}`;
    return config;
  });
};
