import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function Header() {
  const navigate = useNavigate();

  return (
      <AppBar position="static" elevation={0} sx={{
        backgroundColor: '#44000000',
      }}>
        <Toolbar>
          {/* <img 
            src={"/logoOnOrange.png"}  
            alt="BeaconFound Logo"
            style={{width: 50, height: 50, cursor: 'pointer'}}
            onClick={() => navigate('/')}
          /> */}
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={() => navigate('/')} 
          >
            BeaconFound
          </Typography>
          <Button variant="outlined" color="inherit" onClick={() => navigate('/request')}>Request QR Tag</Button>
        </Toolbar>
      </AppBar>
  );
}

export default Header;
