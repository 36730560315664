import { Typography } from "@mui/material"


const AgreeToTermsLabel: React.FC<any> = () => {
    return (
    <Typography variant="body2" color="textSecondary">
        By submitting, I agree to BeaconFound's <a href="/privacy-policy.html">privacy policy</a> and <a href="/tos.html">terms of service</a>.
    </Typography>
    )
}

export default AgreeToTermsLabel