import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Root from './routes/Root';
import { Box, createTheme, CssBaseline, GlobalStyles, responsiveFontSizes } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import ErrorPage from './routes/ErrorPage';
import RedirectLanding from './routes/RedirectLanding';
import FoundItemPage, { loader as foundItemLoader} from './routes/FoundItem';
import RequestTagPage from './routes/RequestTag';
import Reload from './routes/Reload';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let theme = createTheme({
  typography: {
    fontFamily: [
      'Lato',
    ].join(','),
  },
});
theme = responsiveFontSizes(theme);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<Root />}
      errorElement={<ErrorPage />}
    >
      <Route path='/' element={<RedirectLanding url={"https://www.beaconfound.com"} />}/>
      <Route
        path="/found/:url_id/:slug"
        element={<FoundItemPage />}
        loader={foundItemLoader}
      />
      <Route
        path="/request"
        element={<RequestTagPage />}
      />
      <Route path='/privacy-policy.html' element={<Reload />}/>
      <Route path='/tos.html' element={<Reload />}/>
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <React.Fragment>    
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { 
            backgroundColor: '#fab350',
            backgroundImage: 'url(/background.svg), linear-gradient(45deg, #FA9702, #F7CAB0);',
            backgroundSize: 'cover, auto',
            backgroundPosition: 'center, 0% 0%',
            backgroundRepeat: 'no-repeat, repeat',
          },
        }}
      />
        <Box minHeight="100vh" minWidth="100vw" sx={{ flexGrow: 1 }} >
          <RouterProvider router={router} />
        </Box>
    </ThemeProvider>
    </React.Fragment>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
