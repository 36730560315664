import React, { useState } from "react"
import { Grid } from "@mui/material";
import RequestTagForm from "../components/RequestTagForm";
import { RequestFormValues, SubmissionState, submittingHelperTexts } from "../types";
import { API_URL } from "../lib/axios";
import SubmissionSuccess from "../components/SubmissionSuccess";
import SubmissionError from "../components/SubmissionError";
import Loading from "../components/Loading";



const RequestTagPage: React.FC = () => {

	const [submissionState, setSubmissionState] = useState<SubmissionState>("incomplete")

	const sendPostWithRetry = async (body: any, numRetries: number = 3): Promise<any> => {
		var attemptNum = 0
		while (attemptNum <= numRetries) {
			try {
				let response =  await fetch(`${API_URL}/request-tag`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(body),
				});
	
				if (response.status === 200) {
					return response
				} else {
					console.log('Request did not return 200 response')
				}
			} catch {
				console.log('Caught error in request')
			}
			attemptNum++
		}
		console.log('max retries exceeded. returning empty')
		return {}
		
	}

  const onSubmit = async (data: RequestFormValues) => {
		try {
			setSubmissionState("submitting")
			const body: any = { ...data };
			body['address'] = data.addressPT.description
			console.log(body)
			const response = await sendPostWithRetry(body)

			console.log(response)

			if (response.status === 200) {
				setSubmissionState("success")
			} else {
				setSubmissionState("error")
			}
			
		} catch (error) {
			//TODO Handle errors like non-unique email
			console.error(error);
			setSubmissionState("error")
		}
  }

	const child = () => {
		switch (submissionState) {
			case "incomplete":
				return (<RequestTagForm onSubmitForm={onSubmit} />)
			case "submitting":
				return (<Loading message={"Submitting form..."} helperTexts={submittingHelperTexts} helperTimeMs={8000} />)
			case "success":
				return (<SubmissionSuccess />)
			case "error":
				return (<SubmissionError />)
		}
	}

  return (
		<Grid container p={{xs:3, sm:8}} justifyContent="center">
			<Grid item></Grid>
			<Grid item xs={12} sm={9} lg={6}
				bgcolor="#ffffff"
				borderRadius={4}
				p={2}
			>
				{child()}
			</Grid>
			<Grid item></Grid>
		</Grid>	
  )
}

export default RequestTagPage