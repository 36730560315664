import React from "react"
import { useForm } from "react-hook-form"
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import GoogleMaps from "./AutocompleteAddress";
import AgreeToTermsLabel from "./AgreeToTermsLabel";
import { RegisterFormValues, registerFormSchema } from "../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormError } from "./FormError";

const TagInfoForm: React.FC<any> = ({onSubmitForm, title}) => {
  const preferenceOptions = [
    "any",
    "email",
    "phone"
  ]
    
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<RegisterFormValues>({
    mode: "onChange",
    resolver: yupResolver(registerFormSchema),
  })
    
  return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <img 
      src={"/logoOnWhite.png"}  
      alt="BeaconFound Logo"
      style={{width: 150, height: 150, borderRadius: 150/ 2, marginTop: -32}} 
    />
    <Typography component="h1" variant="h5" fontWeight="bold" marginTop={-2}>
      QR Tag Registration
    </Typography>
    <Box component="form" onSubmit={handleSubmit(onSubmitForm)} sx={{ mt: 0 }}>
      <Grid container spacing={2} p={4}>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth {...register("first_name")} label="First Name"/>
          <FormError errorText={errors.first_name?.message} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth {...register("last_name")} label="Last Name"/>
          <FormError errorText={errors.last_name?.message} />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth {...register("email")} label="Email Address"/>
          <FormError errorText={errors.email?.message} />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth {...register("phone")} label="Phone Number"/>
          <FormError errorText={errors.phone?.message} />
        </Grid>
        <Grid item xs={12}>
          <GoogleMaps control={control}/>
          <FormError errorText={errors.addressPT?.message} />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth rows={2} multiline {...register("item_description")} label="Item Description"/>
          <FormError errorText={errors.item_description?.message} />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth rows={2} multiline {...register("found_instructions")} label="Directions if found (e.g. leave at pro shop)"/>
          <FormError errorText={errors.found_instructions?.message} />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="preferences-label">Contact Preferences</InputLabel>
            <Select
              labelId="preferences-label"
              defaultValue={preferenceOptions[0]}
              {...register("preferences")}
            >
              <MenuItem value={preferenceOptions[0]}>Email or Phone</MenuItem>
              <MenuItem value={preferenceOptions[1]}>Email</MenuItem>
              <MenuItem value={preferenceOptions[2]}>Phone</MenuItem>
            </Select>
          </FormControl>
          <FormError errorText={errors.preferences?.message} />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            required
            control={<Checkbox {...register("agreedToTerms")} color="primary" />}
            label={<AgreeToTermsLabel />}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{m: 0}}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      
    </Box>
  </Box>
  )
}

export default TagInfoForm;