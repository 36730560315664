import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { TagRequestFoundProps } from "../types";

interface CardDetailProps {
  name: string | null
  value: string | null
}

function CardDetail({name, value}: CardDetailProps) {
  return (
    <>
      <Typography color="text.primary">
        {name}
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {value}
      </Typography>
    </>
    
  )
}

const LostItem: React.FC<{ item: TagRequestFoundProps }> = ({ item }) => {
  console.log(item.preferences)

  const emailPreference = item.preferences.includes('email') || item.preferences.includes('any')
  const phonePreference = (item.preferences.includes('phone') || item.preferences.includes('any')) && item.phone !== null && item.phone.length > 6 

  const directions = () => {
    return item.found_instructions ? (
      <CardDetail name="Directions" value={item.found_instructions} />
    ) : (<></>)
  }

  const itemDescription = () => {
    return item.item_description ? (
      <CardDetail name={null} value={item.item_description} />
    ) : (<></>)
  }

  const foundItem = item.item_description ? item.item_description : "item"
  const messageBody = `Hi ${item.first_name},\nI found your ${foundItem} and scanned your BeaconFound tag.`

  const getMailtoRef = () => {
    if (emailPreference) {
      let subject = `Found Lost Item - BeaconFound Tag: ${item.id}`
      return `mailto:${item.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(messageBody)}`
    }
    return ""
  } 

  const getSmsRef = () => {
    if (phonePreference) {
      return `sms:${item.phone}?&body=${encodeURIComponent(messageBody)}`
    }
    return ""
  } 

  const getTelRef = () => {
    if (phonePreference) {
      return `tel:${item.phone}`
    }
    return ""
  } 

  const contactOwner = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, ref: string) => {
    window.location.href = ref;
    e.preventDefault();
  }

  const emailButtion = () => {
    return emailPreference ? (
      <Button size="small" variant="outlined" onClick={(e) => {contactOwner(e, getMailtoRef())}}>
        Email Owner
      </Button>
    ) : (<></>)
  }

  const textButton = () => {
    return phonePreference ? (
      <Button size="small" variant="outlined" onClick={(e) => {contactOwner(e, getSmsRef())}}>
        Text Owner
      </Button>
    ) : (<></>)
  }

  const callButton = () => {
    return phonePreference ? (
      <Button size="small" variant="outlined" onClick={(e) => {contactOwner(e, getTelRef())}}>
        Call Owner
      </Button>
    ) : (<></>)
  }

  const contactInfo = () => {
    let email = emailPreference ? (
      <CardDetail name="Email" value={item.email} />
    ) : (<></>)
    let phone = phonePreference && item.phone ? (
      <CardDetail name="Phone Number" value={item.phone} />
    ) : (<></>)
    return (
      <>
        {email}
        {phone}
      </>
    )
  }

  return (
    <Box
      p={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Typography variant="h5" component="div">
        Lost Item
      </Typography>
      {itemDescription()}
      <CardDetail name="Owner" value={`${item.first_name} ${item.last_name}`} />
      {directions()}
      {contactInfo()}

      <Stack
        direction="row"
        spacing={2}
      >
        {emailButtion()}
        {textButton()}
        {callButton()}
      </Stack>

      </Box>
    
  );
};

export default LostItem;
